import { createSlice } from "@reduxjs/toolkit";
import { initialValue } from "./types";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import SweetAlert from "@/components/shared/SweetAlert";
// import  CustomToaster from "@/components/shared/customToast/CustomToaster"

let toastIds: { [key: string]: any } = {};

const slice = createSlice({
  name: "user",
  initialState: initialValue,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetBooks.fulfilled, (state, action) => {
      state.books = action.payload.data.books;
    });
    builder.addCase(thunks.doGetBooks?.rejected, (state, action: any) => {});
    builder.addCase(thunks.doBuyBook?.fulfilled, (state, action) => {
      toast.success(action.payload.data?.message);
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload.data?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
      const toastId = toastIds["doBuyBook"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doBuyBook"];
    });
    builder.addCase(thunks.doBuyBook?.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
      const toastId = toastIds["doBuyBook"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doBuyBook"];
    });
    builder.addCase(thunks.doBuyBook?.pending, (state, action: any) => {
      const toastId = toast.loading("جاري التحميل...");
      toastIds["doBuyBook"] = toastId;
    });
    builder.addCase(thunks.doCheckInvoice?.fulfilled, (state, action) => {
      state.checkInvoice = action.payload.data.link;
    });

    builder.addCase(thunks.doCheckInvoice?.rejected, (state, action) => {});

    // cart
    builder.addCase(thunks.doGetCart.fulfilled, (state, action) => {
      state.cart = action.payload.data;
    });
    builder.addCase(thunks.doGetCart.rejected, (state, action: any) => {});
    builder.addCase(thunks.doAddToCart.fulfilled, (state, action) => {});
    builder.addCase(thunks.doAddToCart.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doUpdateCartItem.fulfilled, (state, action) => {
      state.cart = action.payload.data;
    });
    builder.addCase(thunks.doUpdateCartItem.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action.payload.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doDeleteCartItem.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doDeleteCartItem.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetTeachers.fulfilled, (state, action) => {
      state.teachers = action.payload.data.teachers;
    });
    builder.addCase(thunks.doGetTeachers.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleTeachers.fulfilled, (state, action) => {
      state.singleTeacher = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleTeachers.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetExternalCodes.fulfilled, (state, action) => {
      state.externalCodes = action.payload.data.external_codes;
    });
    builder.addCase(
      thunks.doGetExternalCodes.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doBuyExternalCodes.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(
      thunks.doBuyExternalCodes.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.error,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(thunks.doGetProfile.fulfilled, (state, action) => {
      state.profile = action.payload?.message;
    });
    builder.addCase(thunks.doGetProfile.rejected, (state, action: any) => {});
    builder.addCase(thunks.doUpdateProfile.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doUpdateProfile.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doChangePassword.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doChangePassword.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetHomeDashboard.fulfilled, (state, action) => {
      state.homeDashboard = action.payload.data;
    });
    builder.addCase(
      thunks.doGetHomeDashboard.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doGetInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload.data;
    });
    builder.addCase(thunks.doGetInvoices.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetWallet.fulfilled, (state, action) => {
      state.wallet = action.payload.data;
    });
    builder.addCase(thunks.doGetWallet.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });

    builder.addCase(thunks.doChargeWallet.fulfilled, (state, action) => {
      state.wallet = action.payload.data;
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doChargeWallet.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetSubjects.fulfilled, (state, action) => {
      state.subjects = action.payload.data.subjects;
    });
    builder.addCase(thunks.doGetSubjects.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetMySubjects.fulfilled, (state, action) => {
      state.mySubjects = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMySubjects.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetMyCourses.fulfilled, (state, action) => {
      console.log(action, "action");

      state.myCourses = action.payload?.data;
    });
    builder.addCase(thunks.doGetMyCourses.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetMyCourse.fulfilled, (state, action) => {
      state.myCourse = action.payload.data;
    });
    builder.addCase(thunks.doGetMyCourse.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetMyExams.fulfilled, (state, action) => {
      state.myExams = action.payload.data;
    });
    builder.addCase(thunks.doGetMyExams.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleBook.fulfilled, (state, action) => {
      state.singleBook = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleBook.rejected,
      (state, action: any) => {}
    );
    builder.addCase(
      thunks.doGetSingleExternalCode.fulfilled,
      (state, action) => {
        state.singleExternalCode = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleExternalCode.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data.courses;
      console.log("action.payload.data.courses", action.payload.data.courses);
    });
    builder.addCase(thunks.doGetCourses.rejected, (state, action: any) => {});

    builder.addCase(thunks.doGetSingleCourse.fulfilled, (state, action) => {
      state.singleCourse = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleCourse.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doBuyCourse.fulfilled, (state, action) => {
      toast.success(action.payload.data?.message);
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload.data?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
      const toastId = toastIds["doBuyCourse"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doBuyCourse"];
    });
    builder.addCase(thunks.doBuyCourse.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
      const toastId = toastIds["doBuyCourse"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doBuyCourse"];
    });
    builder.addCase(thunks.doBuyCourse.pending, (state, action: any) => {
      const toastId = toast.loading("جاري التحميل...");
      toastIds["doBuyCourse"] = toastId;
    });
    builder.addCase(
      thunks.doSubscribeWithTeacher.fulfilled,
      (state, action) => {
        // SweetAlert({
        //   title: "عملية ناجحة",
        //   text: action.payload?.message,
        //   iconName: "success",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(
      thunks.doSubscribeWithTeacher.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.error,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(
      thunks.doGetVideoConfiguration.fulfilled,
      (state, action) => {
        toast.success(action.payload.data?.message);
        // SweetAlert({
        //   title: "عملية ناجحة",
        //   text: action.payload.data?.message,
        //   iconName: "success",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(
      thunks.doGetVideoConfiguration.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.error,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(thunks.doUpdateVideoStatus.fulfilled, (state, action) => {
      // toast.success(action.payload.data?.message);
      toast.success(action.payload.data?.message);
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload.data?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(
      thunks.doUpdateVideoStatus.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doUpdateLessonStatus.fulfilled, (state, action) => {
      toast.success(action.payload.data?.message);
    });
    builder.addCase(
      thunks.doUpdateLessonStatus.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doSolveExam.fulfilled, (state, action) => {});
    builder.addCase(thunks.doSolveExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doStartExam.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doStartExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doEndExam.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doEndExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    // full exams
    builder.addCase(thunks.doGetFullExams.fulfilled, (state, action) => {
      state.fullExams = action.payload.data.courses;
    });
    builder.addCase(thunks.doGetFullExams.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetSingleFullExam.fulfilled, (state, action) => {
      state.singleFullExam = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleFullExam.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.error,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(thunks.doGetMyFullExams.fulfilled, (state, action) => {
      state.myFullExams = action.payload.data;
    });
    builder.addCase(thunks.doGetMyFullExams.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doStartFullExam.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doStartFullExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doSolveFullExam.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doSolveFullExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doEndFullExam.fulfilled, (state, action) => {
      // SweetAlert({
      //   title: "عملية ناجحة",
      //   text: action.payload?.message,
      //   iconName: "success",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doEndFullExam.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.error,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetHome.fulfilled, (state, action) => {
      state.home = action?.payload?.data;
    });
    builder.addCase(thunks.doGetHome.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    // comments
    builder.addCase(thunks.doGetCommentsReplies.fulfilled, (state, action) => {
      state.home = action?.payload?.data;
    });
    builder.addCase(
      thunks.doGetCommentsReplies.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.message,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(thunks.doAddComment.fulfilled, (state, action) => {
      state.home = action?.payload?.data;
    });
    builder.addCase(thunks.doAddComment.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doUpdateComment.fulfilled, (state, action) => {
      state.home = action?.payload?.data;
    });
    builder.addCase(thunks.doUpdateComment.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doDeleteComment.fulfilled, (state, action) => {
      state.home = action?.payload?.data;
    });
    builder.addCase(thunks.doDeleteComment.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
  },
});

export const SelectBooks = (state: RootState) => state.user.books;
export const SelectCheckInvoice = (state: RootState) => state.user.checkInvoice;

export const SelectCart = (state: RootState) => state.user.cart;
export const SelectTeachers = (state: RootState) => state.user.teachers;
export const SelectAchievements = (state: RootState) => state.user.achievements;
export const SelectSingleTeacher = (state: RootState) =>
  state.user.singleTeacher;
export const SelectExternalCodes = (state: RootState) =>
  state.user.externalCodes;
export const SelectProfile = (state: RootState) => state.user.profile;
export const SelectHomeDashboard = (state: RootState) =>
  state.user.homeDashboard;
export const SelectInvoices = (state: RootState) => state.user.invoices;
export const SelectWallet = (state: RootState) => state.user.wallet;
export const SelectSubjects = (state: RootState) => state.user.subjects;
export const SelectMySubjects = (state: RootState) => state.user.mySubjects;
export const SelectMyCourses = (state: RootState) => state.user.myCourses;
export const SelectMyCourse = (state: RootState) => state.user.myCourse;
export const SelectMyExams = (state: RootState) => state.user.myExams;

export const SelectSingleBook = (state: RootState) => state.user.singleBook;

export const SelectSingleExternalCode = (state: RootState) =>
  state.user.singleExternalCode;
export const SelectCourses = (state: RootState) => state.user.courses;
export const SelectSingleCourse = (state: RootState) => state.user.singleCourse;

// full exams
export const SelectFullExams = (state: RootState) => state.user.fullExams;
export const SelectSingleFullExam = (state: RootState) =>
  state.user.singleFullExam;
export const SelectMyFullExams = (state: RootState) => state.user.myFullExams;

export const SelectHome = (state: RootState) => state.user.home;
const USER = {
  slice,
  thunks,
};

export default USER;
