import { createSlice } from "@reduxjs/toolkit";
import { initialValue } from "./types";
import thunks from "./thunks";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { deleteCookie, setCookie } from "cookies-next";
import { daysToMilliSecound } from "@/lib/utils";
import SweetAlert from "@/components/shared/SweetAlert";

let toastIds: { [key: string]: any } = {};

const slice = createSlice({
  name: "admin",
  initialState: initialValue,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.doGetProfile.fulfilled, (state, action) => {
      state.profile = action.payload?.message;
    });
    builder.addCase(thunks.doGetProfile.rejected, (state, action: any) => {});
    builder.addCase(thunks.doUpdateProfile.fulfilled, (state, action) => {});
    builder.addCase(
      thunks.doUpdateProfile.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doChangePassword.fulfilled, (state, action) => {});
    builder.addCase(
      thunks.doChangePassword.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSubjects.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
    });
    builder.addCase(thunks.doGetSubjects.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetHome.fulfilled, (state, action) => {
      state.home = action.payload.data;
    });
    builder.addCase(thunks.doGetHome.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.data;
    });
    builder.addCase(
      thunks.doGetPermissions.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetMyPermissions.fulfilled, (state, action) => {
      state.myPermissions = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMyPermissions.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleSubject.fulfilled, (state, action) => {
      state.singleSubject = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleSubject.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateSubject.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateSubject.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateSubject.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateSubject.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteSubject.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteSubject.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });

    builder.addCase(thunks.doGetTeachers.fulfilled, (state, action) => {
      state.teachers = action.payload.data;
    });
    builder.addCase(thunks.doGetTeachers.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleTeacher.fulfilled, (state, action) => {
      state.singleTeacher = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleTeacher.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateTeacher.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateTeacher.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateTeacher.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateTeacher.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteTeacher.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteTeacher.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateTeachersOrder.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateTeachersOrder.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetBooks.fulfilled, (state, action) => {
      state.books = action.payload.data;
    });
    builder.addCase(thunks.doGetBooks.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleBook.fulfilled, (state, action) => {
      state.singleBook = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleBook.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateBook.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateBook.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateBook.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateBook.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteBook.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteBook.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });

    builder.addCase(thunks.doGetExternalCodes.fulfilled, (state, action) => {
      state.externalCodes = action.payload.data;
    });
    builder.addCase(
      thunks.doGetExternalCodes.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetExternalCode.fulfilled, (state, action) => {
      state.singleExternalCode = action.payload.data;
    });
    builder.addCase(
      thunks.doGetExternalCode.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateExternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateExternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateExternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateExternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteExternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteExternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetInternalCodes.fulfilled, (state, action) => {
      state.internalCodes = action.payload.data;
    });
    builder.addCase(
      thunks.doGetInternalCodes.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetInternalCode.fulfilled, (state, action) => {
      state.singleInternalCode = action.payload.data;
    });
    builder.addCase(
      thunks.doGetInternalCode.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateInternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doCreateInternalCode"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doCreateInternalCode"];
    });
    builder.addCase(
      thunks.doCreateInternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
        const toastId = toastIds["doCreateInternalCode"];
        toast.update(toastId, {
          render: action?.payload?.error,
          type: "error",
          isLoading: false,
          autoClose: 100,
        });
        delete toastIds["doCreateInternalCode"];
      }
    );
    builder.addCase(thunks.doCreateInternalCode.pending, (state, action) => {
      const toastId = toast.loading("جاري الاضافة ...");
      toastIds["doCreateInternalCode"] = toastId;
    });
    builder.addCase(thunks.doUpdateInternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateInternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteInternalCode.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteInternalCode.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetModerators.fulfilled, (state, action) => {
      state.moderators = action.payload.data;
    });
    builder.addCase(
      thunks.doGetModerators.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleModerator.fulfilled, (state, action) => {
      state.singleModerator = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleModerator.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateModerator.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateModerator.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateModerator.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateModerator.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteModerator.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteModerator.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });

    builder.addCase(thunks.doGetStudents.fulfilled, (state, action) => {
      state.students = action.payload.data;
    });
    builder.addCase(thunks.doGetStudents.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleStudent.fulfilled, (state, action) => {
      state.singleStudent = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleStudent.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetStudentExams.fulfilled, (state, action) => {
      state.studentExams = action.payload.data;
    });
    builder.addCase(
      thunks.doGetStudentExams.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCorrectQuestion.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCorrectQuestion.rejected, (state, action: any) => {
      //   toast.error(
      //     action.payload && action.payload.error
      //       ? action.payload.error
      //       : action.payload?.message
      //   );
      SweetAlert({
        title: "حدث خطأ",
        text:
          action.payload && action.payload.error
            ? action.payload.error
            : action.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetUsers.fulfilled, (state, action) => {
      state.users = action.payload.data;
    });
    builder.addCase(thunks.doGetUsers.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleuser.fulfilled, (state, action) => {
      state.singleUser = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleuser.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doCreateUser.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateUser.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateUserStatus.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateUserStatus.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateUser.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateUser.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteUser.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteUser.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });

    // login
    builder.addCase(thunks.doAdminLogin.fulfilled, (state, action) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");
      localStorage.removeItem("teacher_id");

      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });

      setCookie(
        "Education_token",
        action.payload.data.token
          ? action.payload.data.token
          : action.payload.data.user.token,
        { maxAge: daysToMilliSecound(1000) }
      );

      if (action.payload.data.role === 0) {
        state.adminLogged.role = "admin";
      } else if (action.payload.data.role === 2) {
        setCookie("teacher__teacher", "teacher__teacher", {
          maxAge: daysToMilliSecound(1000),
        });
        state.adminLogged.role = "teacher";
      } else if (action.payload.data.user?.role === 3) {
        /* moderator for platform */
        setCookie("platform__moderator", "platform__moderator");
        state.adminLogged.role = "moderator";
      } else if (action.payload.data.user?.role === 4) {
        /* moderator for teacher */
        setCookie("teacher__moderator", "teacher__moderator");
        state.adminLogged.role = "moderator";
      }

      state.adminLogged.adminToken = action.payload.data.token;
      state.adminLogged.isLoggedIn = true;
      state.adminLogged.adminData = action.payload.data;
      localStorage.setItem(
        "username",
        action.payload.data.name
          ? action.payload.data.name
          : action.payload.data.user.name
      );
      localStorage.setItem(
        "image",
        action.payload.data.image
          ? action.payload.data.image
          : action.payload.data.user.image
      );
      localStorage.setItem(
        "teacher_id",
        action.payload.data.teacher_id
          ? action.payload.data?.teacher_id
          : action.payload.data?.user?.teacher_id
      );
    });
    builder.addCase(thunks.doAdminLogin.rejected, (state, action: any) => {
      deleteCookie("Education_token");
      deleteCookie("teacher__teacher");
      deleteCookie("Education_User_token");
      deleteCookie("seveezToken");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("username");
      localStorage.removeItem("image");
      localStorage.removeItem("teacher_id");

      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
      state.adminLogged.role = "";
      state.adminLogged.isLoggedIn = false;
      state.adminLogged.adminToken = null;
      state.adminLogged.adminData = {};
      localStorage.removeItem("username");
      localStorage.removeItem("image");
      localStorage.removeItem("teacher_id");
    });
    builder.addCase(thunks.doGetAllVideos.fulfilled, (state, action) => {
      state.videos = action.payload.data;
    });
    builder.addCase(thunks.doGetAllVideos.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetTeacherVideos.fulfilled, (state, action) => {
      state.teacherVideos = action.payload.data;
    });
    builder.addCase(
      thunks.doGetTeacherVideos.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doGetSingleVideo.fulfilled, (state, action) => {
      state.singleVideo = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleVideo.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateVideo.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateVideo.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateVideo.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateVideo.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteVideo.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteVideo.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    // courses
    builder.addCase(thunks.doGetCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data;
    });
    builder.addCase(thunks.doGetCourses.rejected, (state, action: any) => {});
    builder.addCase(thunks.doGetSingleCourse.fulfilled, (state, action) => {
      state.singleCourse = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleCourse.rejected,
      (state, action: any) => {}
    );
    builder.addCase(thunks.doCreateCourse.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doCreateCourse"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doCreateFullExam"];
    });
    builder.addCase(thunks.doCreateCourse.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doCreateCourse"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(thunks.doCreateCourse.pending, (state, action) => {
      const toastId = toast.loading("جاري انشاء الكورس...");
      toastIds["doCreateCourse"] = toastId;
    });
    builder.addCase(thunks.doUpdateCourse.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });

      const toastId = toastIds["doUpdateCourse"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(thunks.doUpdateCourse.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doUpdateCourse"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(thunks.doUpdateCourse.pending, (state, action) => {
      const toastId = toast.loading("جاري تحديث الكورس...");
      toastIds["doUpdateCourse"] = toastId;
    });
    builder.addCase(thunks.doDeleteCourse.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteCourse.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    // courses - chapters
    builder.addCase(thunks.doCreateCourseChapter.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });

      const toastId = toastIds["doCreateCourseChapter"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(
      thunks.doCreateCourseChapter.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
        const toastId = toastIds["doCreateCourseChapter"];
        toast.update(toastId, {
          render: action?.payload?.error,
          type: "error",
          isLoading: false,
          autoClose: 100,
        });
      }
    );
    builder.addCase(thunks.doCreateCourseChapter.pending, (state, action) => {
      const toastId = toast.loading("جاري انشاء الفصل...");
      toastIds["doCreateCourseChapter"] = toastId;
    });
    builder.addCase(thunks.doUpdateCourseChapter.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doUpdateCourseChapter"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(
      thunks.doUpdateCourseChapter.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
        const toastId = toastIds["doUpdateCourseChapter"];
        toast.update(toastId, {
          render: action?.payload?.error,
          type: "error",
          isLoading: false,
          autoClose: 100,
        });
      }
    );
    builder.addCase(thunks.doUpdateCourseChapter.pending, (state, action) => {
      const toastId = toast.loading("جاري تحديث الفصل...");
      toastIds["doUpdateCourseChapter"] = toastId;
    });
    builder.addCase(thunks.doDeleteCourseChapter.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteCourseChapter.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    // courses - chapters - lessons
    builder.addCase(thunks.doCreateCourseLesson.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });

      const toastId = toastIds["doCreateCourseLesson"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
    });
    builder.addCase(
      thunks.doCreateCourseLesson.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });

        const toastId = toastIds["doCreateCourseLesson"];
        toast.update(toastId, {
          render: action?.payload?.error,
          type: "error",
          isLoading: false,
          autoClose: 100,
        });
      }
    );
    builder.addCase(thunks.doCreateCourseLesson.pending, (state, action) => {
      const toastId = toast.loading("جاري انشاء الدرس...");
      toastIds["doCreateCourseLesson"] = toastId;
    });
    // courses - chapters - lessons
    builder.addCase(thunks.doDeleteCourseLesson.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteCourseLesson.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    // Money
    builder.addCase(thunks.doGetMoneyRequests.fulfilled, (state, action) => {
      state.moneyRequests = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMoneyRequests.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doGetMoneyRequest.fulfilled, (state, action) => {
      state.moneyRequest = action.payload.data;
    });
    builder.addCase(
      thunks.doGetMoneyRequest.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doCreateMoneyRequest.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });

    builder.addCase(
      thunks.doCreateMoneyRequest.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doUpdateMoneyRequest.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateMoneyRequest.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    // Finances
    builder.addCase(thunks.doGetFinances.fulfilled, (state, action) => {
      state.finances = action.payload.data;
    });
    builder.addCase(thunks.doGetFinances.rejected, (state, action: any) => {});

    builder.addCase(
      thunks.doGetSingleBookSalesInfo.fulfilled,
      (state, action) => {
        state.singleBookSalesInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleBookSalesInfo.rejected,
      (state, action: any) => {}
    );
    builder.addCase(
      thunks.doGetSingleTeacherFinanceInfo.fulfilled,
      (state, action) => {
        state.singleTeacherFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleTeacherFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleUserChargeWalletInfo.fulfilled,
      (state, action) => {
        state.singleUserChargeWalletInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleUserChargeWalletInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleCourseFinanceInfo.fulfilled,
      (state, action) => {
        state.singleCourseFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleCourseFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(
      thunks.doGetSingleFullExamFinanceInfo.fulfilled,
      (state, action) => {
        state.singleFullExamFinanceInfo = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleFullExamFinanceInfo.rejected,
      (state, action: any) => {}
    );

    builder.addCase(thunks.doUpdateLesson.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });

    builder.addCase(thunks.doUpdateLesson.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });

    builder.addCase(thunks.doUpdateLessonsOrder.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });

    builder.addCase(
      thunks.doUpdateLessonsOrder.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetTeacherChapters.fulfilled, (state, action) => {
      state.teacherChapters = action.payload.data;
    });

    builder.addCase(
      thunks.doGetTeacherChapters.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetTeacherCourses.fulfilled, (state, action) => {
      state.teacherCourses = action.payload.data;
    });

    builder.addCase(
      thunks.doGetTeacherCourses.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doDuplicateChapter.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });

    builder.addCase(
      thunks.doDuplicateChapter.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    // full exam
    builder.addCase(thunks.doCreateFullExam.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doCreateFullExam"]; // Retrieve the toastId
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false, // Update to success message
        autoClose: 100, // Auto close after 3 seconds
      });
      delete toastIds["doCreateFullExam"];
    });
    builder.addCase(thunks.doCreateFullExam.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doCreateFullExam"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doCreateFullExam"];
    });
    builder.addCase(thunks.doCreateFullExam.pending, (state, action) => {
      const toastId = toast.loading("جاري التحميل");
      toastIds["doCreateFullExam"] = toastId;
    });
    builder.addCase(thunks.doUpdateFullExam.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doUpdateFullExam"];
      toast.update(toastId, {
        render: action.payload?.message,
        type: "success",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doUpdateFullExam"];
    });
    builder.addCase(thunks.doUpdateFullExam.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
      const toastId = toastIds["doUpdateFullExam"];
      toast.update(toastId, {
        render: action?.payload?.error,
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
      delete toastIds["doUpdateFullExam"];
    });
    builder.addCase(thunks.doUpdateFullExam.pending, (state, action) => {
      const toastId = toast.loading("جاري التحميل");
      toastIds["doUpdateFullExam"] = toastId;
    });
    builder.addCase(thunks.doGetFullExams.fulfilled, (state, action) => {
      state.fullExams = action.payload.data;
    });
    builder.addCase(thunks.doGetFullExams.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetSingleFullExam.fulfilled, (state, action) => {
      state.singleFullExam = action.payload.data;
    });
    builder.addCase(
      thunks.doGetSingleFullExam.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteFullExam.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteFullExam.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateFullExamQuestion.fulfilled,
      (state, action) => {
        SweetAlert({
          title: "عملية ناجحة",
          text: action.payload?.message,
          iconName: "success",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doCreateFullExamQuestion.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doDeleteFullExamQuestion.fulfilled,
      (state, action) => {
        SweetAlert({
          title: "عملية ناجحة",
          text: action.payload?.message,
          iconName: "success",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doDeleteFullExamQuestion.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    // doGetWallet
    builder.addCase(thunks.doGetWallet.fulfilled, (state, action) => {
      state.chartByMonth = action.payload.data;
    });
    builder.addCase(thunks.doGetWallet.rejected, (state, action: any) => {});
    builder.addCase(
      thunks.doUpdateFullExamQuestionsOrder.fulfilled,
      (state, action) => {
        SweetAlert({
          title: "عملية ناجحة",
          text: action.payload?.message,
          iconName: "success",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doUpdateFullExamQuestionsOrder.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(
      thunks.doUpdateFullExamQuestion.fulfilled,
      (state, action) => {
        SweetAlert({
          title: "عملية ناجحة",
          text: action.payload?.message,
          iconName: "success",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doUpdateFullExamQuestion.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(
      thunks.doGetSingleFullExamQuestion.fulfilled,
      (state, action) => {
        state.singleFullExamQuestion = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleFullExamQuestion.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doGetOrders.fulfilled, (state, action) => {
      state.orders = action.payload.data;
    });
    builder.addCase(thunks.doGetOrders.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetOrdersForExport.fulfilled,
      (state, action) => {}
    );
    builder.addCase(
      thunks.doGetOrdersForExport.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doGetComments.fulfilled, (state, action) => {
      state.comments = action.payload.data;
    });
    builder.addCase(thunks.doGetComments.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doAddComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doAddComment.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doUpdateComment.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteComment.fulfilled, (state, action) => {});
    builder.addCase(thunks.doDeleteComment.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetCourseUsers.fulfilled, (state, action) => {
      state.courseUsers = action.payload.data;
    });
    builder.addCase(thunks.doGetCourseUsers.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetCourseLessonsAnalytics.fulfilled,
      (state, action) => {
        state.courseLessonsAnalytics = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetCourseLessonsAnalytics.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doGetCourseLessonsStudentsAnalytics.fulfilled,
      (state, action) => {
        state.courseLessonsStudentsAnalytics = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetCourseLessonsStudentsAnalytics.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doGetCourseTestsAnalytics.fulfilled,
      (state, action) => {
        state.courseTestsStudentsAnalytics = action.payload;
      }
    );
    builder.addCase(
      thunks.doGetCourseTestsAnalytics.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doGetTeacherLessons.fulfilled, (state, action) => {
      state.teacherLessons = action.payload;
    });
    builder.addCase(
      thunks.doGetTeacherLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doImportLesson.fulfilled, (state, action) => {
      state.teacherLessons = action.payload;
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doImportLesson.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    // test bank
    builder.addCase(thunks.doGetTestBank.fulfilled, (state, action) => {
      state.testBank = action.payload.data;
    });
    builder.addCase(thunks.doGetTestBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetSingleTestBank.fulfilled, (state, action) => {
      state.singleTestBank = action.payload;
    });
    builder.addCase(
      thunks.doGetSingleTestBank.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doCreateTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateTestBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateTestBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteTestBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    // door test bank
    builder.addCase(thunks.doGetDoorTestBank.fulfilled, (state, action) => {
      state.doorTestBank = action.payload.data;
    });
    builder.addCase(thunks.doGetDoorTestBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetSingleDoorTestBank.fulfilled,
      (state, action) => {
        state.singleDoorTestBank = action.payload;
      }
    );
    builder.addCase(
      thunks.doGetSingleDoorTestBank.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doCreateDoorTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateDoorTestBank.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateDoorTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateDoorTestBank.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteDoorTestBank.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteDoorTestBank.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doGetDoorLessons.fulfilled, (state, action) => {
      state.lessonsDoor = action.payload.data;
    });
    builder.addCase(thunks.doGetDoorLessons.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetSingleDoorLessons.fulfilled,
      (state, action) => {
        // state.singleDoorLessons = action.payload;
      }
    );
    builder.addCase(
      thunks.doGetSingleDoorLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doCreateDoorLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateDoorLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateDoorLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateDoorLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteDoorLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteDoorLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    // levels lesson
    builder.addCase(thunks.doGetLevelsLessons.fulfilled, (state, action) => {
      state.levelsLesson = action.payload.data;
    });
    builder.addCase(
      thunks.doGetLevelsLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doGetSingleLevelsLessons.fulfilled,
      (state, action) => {
        // state.singleDoorLessons = action.payload;
      }
    );
    builder.addCase(
      thunks.doGetSingleLevelsLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doCreateLevelsLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateLevelsLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateLevelsLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateLevelsLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteLevelsLessons.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteLevelsLessons.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doGetStudentCourses.fulfilled,
      (state, action: any) => {
        state.studentCourses = action.payload.data;
      }
    );
    // questions
    builder.addCase(thunks.doGetQuestions.fulfilled, (state, action) => {
      state.questions = action.payload.data;
    });
    builder.addCase(thunks.doGetQuestions.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    // builder.addCase(thunks.doGetSingleQuestion.fulfilled, (state, action) => {
    //   state.singleQuestion = action.payload.data;
    // });
    // builder.addCase(
    //   thunks.doGetSingleQuestion.rejected,
    //   (state, action: any) => {
    //     SweetAlert({
    //       title: "حدث خطأ",
    //       text: action?.payload?.error,
    //       iconName: "error",
    //       buttonText: "حسنا",
    //     });
    //   }
    // );
    builder.addCase(thunks.doCreateQuestions.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doCreateQuestions.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateQuestions.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doUpdateQuestions.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteQuestions.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action.payload.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doDeleteQuestions.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.error,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetStudentCourses.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.error,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );

    builder.addCase(thunks.doGetUserSessions.fulfilled, (state, action) => {
      // console.log(action, "action");
      state.userSessions = action?.payload;
    });
    builder.addCase(thunks.doGetUserSessions.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doChangeStatus.fulfilled, (state, action: any) => {
      // console.log(action, "action");
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doChangeStatus.rejected, (state, action: any) => {
      // console.log(action, "action");
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetDigitalExams.fulfilled, (state, action) => {
      state.digitalExams = action.payload.data;
    });
    builder.addCase(thunks.doGetDigitalExams.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doGetSingleDigitalExams.fulfilled,
      (state, action) => {
        state.singleDigitalExam = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetSingleDigitalExams.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doCreateDigitalExams.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doCreateDigitalExams.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doUpdateDigitalExams.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doUpdateDigitalExams.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doDeleteDigitalExams.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(
      thunks.doDeleteDigitalExams.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    //specific routes
    builder.addCase(thunks.doGetTeacherBanks.fulfilled, (state, action) => {
      state.teacherBanks = action.payload.data;
    });
    builder.addCase(thunks.doGetTeacherBanks.rejected, (state, action: any) => {
      // SweetAlert({
      //   title: "حدث خطأ",
      //   text: action?.payload?.message,
      //   iconName: "error",
      //   buttonText: "حسنا",
      // });
    });
    builder.addCase(thunks.doGetDoorsInBank.fulfilled, (state, action) => {
      state.doorsInBank = action.payload.data;
    });
    builder.addCase(thunks.doGetDoorsInBank.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doGetLessonsInDoor.fulfilled, (state, action) => {
      state.lessonsInDoor = action.payload.data;
    });
    builder.addCase(
      thunks.doGetLessonsInDoor.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(thunks.doGetLevelsInLesson.fulfilled, (state, action) => {
      state.levelsInLesson = action.payload.data;
    });
    builder.addCase(
      thunks.doGetLevelsInLesson.rejected,
      (state, action: any) => {
        SweetAlert({
          title: "حدث خطأ",
          text: action?.payload?.message,
          iconName: "error",
          buttonText: "حسنا",
        });
      }
    );
    builder.addCase(
      thunks.doGetTeacherSchoolYears.fulfilled,
      (state, action) => {
        state.teacherSchoolYears = action.payload.data;
      }
    );
    builder.addCase(
      thunks.doGetTeacherSchoolYears.rejected,
      (state, action: any) => {
        // SweetAlert({
        //   title: "حدث خطأ",
        //   text: action?.payload?.message,
        //   iconName: "error",
        //   buttonText: "حسنا",
        // });
      }
    );
    builder.addCase(thunks.doSendTelegram.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doSendTelegram.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doSendFacebook.fulfilled, (state, action) => {
      SweetAlert({
        title: "عملية ناجحة",
        text: action?.payload?.message,
        iconName: "success",
        buttonText: "حسنا",
      });
    });
    builder.addCase(thunks.doSendFacebook.rejected, (state, action: any) => {
      SweetAlert({
        title: "حدث خطأ",
        text: action?.payload?.message,
        iconName: "error",
        buttonText: "حسنا",
      });
    });
  },
});

// export const SelectAdminData = (state: RootState) => state.admin.adminLogged;

export const SelectProfile = (state: RootState) => state.user.profile;
export const SelectPermissions = (state: RootState) => state.admin.permissions;
export const SelectMyPermissions = (state: RootState) =>
  state.admin.myPermissions;
export const SelectHome = (state: RootState) => state.admin.home;

export const SelectSubjects = (state: RootState) => state.admin.subjects;
export const SelectSingleSubject = (state: RootState) =>
  state.admin.singleSubject;

export const SelectTeachers = (state: RootState) => state.admin.teachers;
export const SelectSingleTeacher = (state: RootState) =>
  state.admin.singleTeacher;

export const SelectBooks = (state: RootState) => state.admin.books;
export const SelectSingleBook = (state: RootState) => state.admin.singleBook;

export const SelectExternalCodes = (state: RootState) =>
  state.admin.externalCodes;
export const SelectSingleExternalCode = (state: RootState) =>
  state.admin.singleExternalCode;
export const SelectInternalCodes = (state: RootState) =>
  state.admin.internalCodes;
export const SelectSingleInternalCode = (state: RootState) =>
  state.admin.singleInternalCode;

export const SelectModerators = (state: RootState) => state.admin.moderators;
export const SelectSingleModerator = (state: RootState) =>
  state.admin.singleModerator;

export const SelectStudents = (state: RootState) => state.admin.students;
export const SelectSingleStudent = (state: RootState) =>
  state.admin.singleStudent;
export const SelectStudentExams = (state: RootState) =>
  state.admin.studentExams;

export const SelectUsers = (state: RootState) => state.admin.users;
export const SelectSingleUser = (state: RootState) => state.admin.singleUser;

export const SelectVideos = (state: RootState) => state.admin.videos;
export const SelectTeacherVideos = (state: RootState) =>
  state.admin.teacherVideos;
export const SelectSingleVideo = (state: RootState) => state.admin.singleVideo;
export const SelectCourses = (state: RootState) => state.admin.courses;
export const SelectSingleCourse = (state: RootState) =>
  state.admin.singleCourse;
export const SelectTeacherChapters = (state: RootState) =>
  state.admin.teacherChapters;
export const SelectTeacherCourses = (state: RootState) =>
  state.admin.teacherCourses;
// full exams
export const SelectFullExams = (state: RootState) => state.admin.fullExams;
export const SelectSingleExam = (state: RootState) =>
  state.admin.singleFullExam;

// Money
export const SelectMoneyRequests = (state: RootState) =>
  state.admin.moneyRequests;
export const SelectMoneyRequest = (state: RootState) =>
  state.admin.moneyRequest;

// Finances
export const SelectFinances = (state: RootState) => state.admin.finances;
export const SelectSingleBookSalesInfo = (state: RootState) =>
  state.admin.singleBookSalesInfo;
export const SelectSingleTeacherFinanceInfo = (state: RootState) =>
  state.admin.singleTeacherFinanceInfo;
export const SelectSingleUserChargeWalletInfo = (state: RootState) =>
  state.admin.singleUserChargeWalletInfo;
export const SelectSingleCourseFinanceInfo = (state: RootState) =>
  state.admin.singleCourseFinanceInfo;
export const SelectSingleFullExamFinanceInfo = (state: RootState) =>
  state.admin.singleFullExamFinanceInfo;

// ChartByMonth
export const SelectWallet = (state: RootState) => state.user.chartByMonth;

export const SelectSingleFullExamQuestion = (state: RootState) =>
  state.admin.singleFullExamQuestion;

export const SelectOrders = (state: RootState) => state.admin.orders;
export const SelectCommentsForCourse = (state: RootState) =>
  state.admin.comments;

export const SelectCourseUsers = (state: RootState) => state.admin.courseUsers;
export const SelectCourseLessonsAnalytics = (state: RootState) =>
  state.admin.courseLessonsAnalytics;
export const SelectCourseLessonsStudentsAnalytics = (state: RootState) =>
  state.admin.courseLessonsStudentsAnalytics;
export const SelectCourseTestStudentsAnalytics = (state: RootState) =>
  state.admin.courseTestsStudentsAnalytics;

export const SelectTeacherLessons = (state: RootState) =>
  state.admin.teacherLessons;

// test bank
export const SelectTestBanks = (state: RootState) => state.admin.testBank;
export const SelectSingleTestBank = (state: RootState) =>
  state.admin.singleTestBank;

// door test bank
export const SelectDoorTestBanks = (state: RootState) =>
  state.admin.doorTestBank;
export const SelectSingleDoorTestBank = (state: RootState) =>
  state.admin.singleDoorTestBank;

// lessons door
export const SelectLessonsDoor = (state: RootState) => state.admin.lessonsDoor;
// levels lessons
export const SelectLevelsLessons = (state: RootState) =>
  state.admin.levelsLesson;

// questions
export const SelectQuestions = (state: RootState) => state.admin.questions;

// student courses
export const SelectStudentCourses = (state: RootState) =>
  state.admin.studentCourses;

export const SelectUserSessions = (state: RootState) =>
  state.admin.userSessions;

// digital exams
export const SelectDigitalExams = (state: RootState) =>
  state.admin.digitalExams;

export const SelectSingleDigitalExam = (state: RootState) =>
  state.admin.singleDigitalExam;

// specific routes
export const SelectTeacherBanks = (state: RootState) =>
  state.admin.teacherBanks;
export const SelectDoorsInBank = (state: RootState) => state.admin.doorsInBank;
export const SelectLessonsInDoor = (state: RootState) =>
  state.admin.lessonsInDoor;
export const SelectLevelsInLesson = (state: RootState) =>
  state.admin.levelsInLesson;

export const SelectTeacherSchoolYears = (state: RootState) =>
  state.admin.teacherSchoolYears;

const ADMIN = {
  slice,
  thunks,
};

export default ADMIN;
